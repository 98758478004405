<template>
  <div>
    <!-- v-b-popover.hover.topleft="'Click to View'" -->

    <!--  class="pin-operation pin-purple" -->
    <b-button variant="primary progress from-button" @click="pricesModal()">
      Check Prices
      <!-- <b-icon-search></b-icon-search> -->
    </b-button>
    <b-modal
      v-model="pricesmodalShow"
      centered
      no-close-on-backdrop
      scrollable
      title="te prices"
      id="pricesModal"
      ref="modal"
      size="lg"
    >
      <b-overlay :show="loading">
        <b-table
          hover
          id="'prices_table'"
          :items="pricesmodaldata"
          class="table-link"
        ></b-table>
      </b-overlay>
      <template v-slot:modal-footer="{ close }">
        <b-button size="sm" variant="secondary" @click="close()">
          {{ $t('page.close') }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import apiBus from '@/common/apiBus/index';
export default {
  name: 'TePrices',
  data: function() {
    return {
      // te prices 模态框配置
      pricesmodalShow: false,
      pricesmodaldata: null,
      loading: true
    };
  },
  components: {},
  props: {
    productId: String,
    targetCountry: String,
    distributorId: String
  },
  methods: {
    // te prices 模态框展示
    pricesModal: function(data) {
      console.log(data);
      this.pricesmodalShow = true;
      console.log({
        product_id: this.productId,
        target_country: this.targetCountry,
        distributor_id: this.distributorId
      });
      return apiBus.sku
        .getProductTePrice({
          product_id: this.productId,
          target_country: this.targetCountry,
          distributor_id: this.distributorId
        })
        .then(data => {
          console.log(data);
          let arr = [];
          data.data.data.forEach(function(i) {
            arr.push({
              target_sales_country: i.target_country,
              customer_group: i.group_name,
              customer_group_price: i.stand_price_incl_duty_tax
            });
          });
          this.pricesmodaldata = arr;
          this.loading = false;
        });
      // this.pricesmodaldata = data.te_prices_arr;
    },

    init: function() {}
  },
  computed: {
    // category_id() {
    //   return this.$route.query.category_id;
    // }
  },
  mounted() {
    this.init();
  }
};
</script>
<style scoped>
b {
  font-weight: bold;
}
.list-unstyled {
  position: relative;
}
.modal-body .position-relative {
  overflow-y: auto;
  overflow-x: hidden;
  height: 500px;
}
.media {
  margin-bottom: 8px;
  background-color: #faf9fc;
  padding: 8px;
}
.media-box {
  position: relative;
  margin-bottom: 15px;
}
.pseudo-slider,
.pseudo-slider-return {
  color: #00a0e8 !important;
}
.pseudo-slider,
.pseudo-slider:hover {
  padding: 0 5px;
  background-color: transparent !important;
  border: 0;
  position: absolute;
  top: 0;
  right: 0;
}
.pseudo-slider-return,
.pseudo-slider-return:hover {
  padding: 0 5px;
  background-color: transparent !important;
  border: 0;
  position: absolute;
  top: 0;
  left: 0;
}
b,
strong {
  font-weight: bold;
}
.subscript {
  position: absolute;
  right: 8px;
  bottom: 8px;
}
.media-text {
  margin-top: 10px;
}
</style>
